// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-berufsmantel-js": () => import("./../../../src/pages/berufsmantel.js" /* webpackChunkName: "component---src-pages-berufsmantel-js" */),
  "component---src-pages-cart-js": () => import("./../../../src/pages/cart.js" /* webpackChunkName: "component---src-pages-cart-js" */),
  "component---src-pages-classico-hose-js": () => import("./../../../src/pages/classico-hose.js" /* webpackChunkName: "component---src-pages-classico-hose-js" */),
  "component---src-pages-classico-jacke-js": () => import("./../../../src/pages/classico-jacke.js" /* webpackChunkName: "component---src-pages-classico-jacke-js" */),
  "component---src-pages-classico-shorts-js": () => import("./../../../src/pages/classico-shorts.js" /* webpackChunkName: "component---src-pages-classico-shorts-js" */),
  "component---src-pages-damenkasack-js": () => import("./../../../src/pages/damenkasack.js" /* webpackChunkName: "component---src-pages-damenkasack-js" */),
  "component---src-pages-dynamix-js": () => import("./../../../src/pages/dynamix.js" /* webpackChunkName: "component---src-pages-dynamix-js" */),
  "component---src-pages-fleecejacke-primo-js": () => import("./../../../src/pages/fleecejacke-primo.js" /* webpackChunkName: "component---src-pages-fleecejacke-primo-js" */),
  "component---src-pages-gilet-primo-knopfen-js": () => import("./../../../src/pages/gilet-primo-knopfen.js" /* webpackChunkName: "component---src-pages-gilet-primo-knopfen-js" */),
  "component---src-pages-gilet-primo-rv-js": () => import("./../../../src/pages/gilet-primo-rv.js" /* webpackChunkName: "component---src-pages-gilet-primo-rv-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jackets-js": () => import("./../../../src/pages/jackets.js" /* webpackChunkName: "component---src-pages-jackets-js" */),
  "component---src-pages-latzhose-primo-js": () => import("./../../../src/pages/latzhose-primo.js" /* webpackChunkName: "component---src-pages-latzhose-primo-js" */),
  "component---src-pages-overall-primo-js": () => import("./../../../src/pages/overall-primo.js" /* webpackChunkName: "component---src-pages-overall-primo-js" */),
  "component---src-pages-pants-js": () => import("./../../../src/pages/pants.js" /* webpackChunkName: "component---src-pages-pants-js" */),
  "component---src-pages-parka-primo-aussenjacke-js": () => import("./../../../src/pages/parka-primo-aussenjacke.js" /* webpackChunkName: "component---src-pages-parka-primo-aussenjacke-js" */),
  "component---src-pages-parka-primo-innenjacke-js": () => import("./../../../src/pages/parka-primo-innenjacke.js" /* webpackChunkName: "component---src-pages-parka-primo-innenjacke-js" */),
  "component---src-pages-polar-js": () => import("./../../../src/pages/polar.js" /* webpackChunkName: "component---src-pages-polar-js" */),
  "component---src-pages-shared-js": () => import("./../../../src/pages/shared.js" /* webpackChunkName: "component---src-pages-shared-js" */),
  "component---src-pages-softshelljacke-js": () => import("./../../../src/pages/softshelljacke.js" /* webpackChunkName: "component---src-pages-softshelljacke-js" */),
  "component---src-pages-softshelljacke-plus-js": () => import("./../../../src/pages/softshelljacke-plus.js" /* webpackChunkName: "component---src-pages-softshelljacke-plus-js" */),
  "component---src-pages-trend-gilet-js": () => import("./../../../src/pages/trend-gilet.js" /* webpackChunkName: "component---src-pages-trend-gilet-js" */),
  "component---src-pages-trend-js": () => import("./../../../src/pages/trend.js" /* webpackChunkName: "component---src-pages-trend-js" */),
  "component---src-pages-ultimate-trousers-js": () => import("./../../../src/pages/ultimate-trousers.js" /* webpackChunkName: "component---src-pages-ultimate-trousers-js" */)
}

